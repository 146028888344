import { ApolloClient } from '@apollo/client';

import { getCache } from './cache';
import { createLink } from './link';

type AddApolloStateArgs = { [key: string]: any } & {
  props?: { [key: string]: any };
};
type AddApolloStateExtended = { [key: string]: any } & {
  props: { [key: string]: any } & { initialApolloState: any };
};
type AddApolloState = (args: AddApolloStateArgs) => AddApolloStateExtended;

export const ssrApolloService = () => {
  const apolloClient = new ApolloClient({
    ssrMode: true,
    link: createLink(),
    cache: getCache(true),
  });

  const addApolloState: AddApolloState = (state = {}) => {
    const initialApolloState = apolloClient.cache.extract();

    return {
      ...state,
      props: {
        ...(state.props || {}),
        initialApolloState,
      },
    };
  };

  return {
    apolloClient,
    addApolloState,
  };
};

import { ssrApolloService } from '@scorenco/core';
import { NextPageWithLayout } from '@scorenco/next';
import * as Sentry from '@sentry/nextjs';

import { ErrorComponent } from '../components';
import { WebLayout } from '../layouts';

import {
  WebClubCheckOldUrlDocument,
  WebTeamCheckOldUrlDocument,
  WebCompetitionCheckOldUrlDocument,
  WebEventCheckOldUrlDocument,
  WebLevelForSeasonCheckOldUrlDocument,
  WebLeagueCheckOldUrlDocument,
} from './_internal/Redirect.query.generated';

const Error: NextPageWithLayout<{
  sentryEventId?: string;
  statusCode?: number;
  errorMessage?: string;
}> = () => {
  return <ErrorComponent />;
};

Error.getLayout = WebLayout.buildGetLayout();

Error.getInitialProps = WebLayout.buildGetInitialProps({}, [
  async (contextData) => {
    const { apolloClient } = ssrApolloService();

    const { res, req, err } = contextData;
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

    // Si on est côté serveur, on vérifie si l'URL correspond à une ancienne URL.
    // Si c'est le cas, on redirige vers la nouvelle URL.
    if (req && res) {
      let redirectTo;
      let url = req.headers['x-original-url']?.toString() || req.url || '';
      url = url.replace(/^\/sport\//, '/');

      // Url d'une équipe de la v1 ?
      if (/\/[\w-]+\/clubs\/[\w-]+\/equipes\/[\w-]+\/?/.test(url)) {
        const result = await apolloClient.query({
          query: WebTeamCheckOldUrlDocument,
          variables: {
            pathname: url.endsWith('/') ? url : url + '/',
          },
        });
        redirectTo = result.data.teams_team?.[0]?.url;
      }

      // Url d'un club de la v1 ?
      else if (/\/[\w-]+\/clubs\/[\w-]+\/?/.test(url)) {
        const result = await apolloClient.query({
          query: WebClubCheckOldUrlDocument,
          variables: {
            pathname: url.endsWith('/') ? url : url + '/',
          },
        });
        redirectTo = result.data.clubs_club?.[0]?.url;
      }

      // Url d'une competition de la v1 ?
      else if (/^\/[\w-]+\/competitions\/.*?/.test(url)) {
        const result = await apolloClient.query({
          query: WebCompetitionCheckOldUrlDocument,
          variables: {
            pathname: url.endsWith('/') ? url : url + '/',
          },
        });

        redirectTo = result.data.competitions_competition?.[0]?.url;

        // Si ce n'est pas une competition, un level?
        if (!redirectTo) {
          const result = await apolloClient.query({
            query: WebLevelForSeasonCheckOldUrlDocument,
            variables: {
              pathname: url.endsWith('/') ? url.slice(0, -1) : url,
            },
          });

          redirectTo =
            result.data.competitions_level_for_season?.[0]?.detail?.[0]?.url;
        }

        // Si ce n'est pas une competition, une ligue?
        if (!redirectTo) {
          const result = await apolloClient.query({
            query: WebLeagueCheckOldUrlDocument,
            variables: {
              pathname: url.endsWith('/') ? url.slice(0) : url + '/',
            },
          });

          redirectTo = result.data.competitions_league?.[0]?.detail?.[0]?.url;
        }
      }

      // Url d'un event de la v1 ?
      else if (/^\/live\/.*?/.test(url)) {
        const result = await apolloClient.query({
          query: WebEventCheckOldUrlDocument,
          variables: {
            pathname: url.endsWith('/') ? url : url + '/',
          },
        });

        redirectTo = result.data.competitions_event?.[0]?.detail?.[0]?.url;
      }

      if (redirectTo) {
        res.writeHead(307, {
          Location: redirectTo,
        });
        res.end();
        return { redirectTo };
      }
    }

    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    if (contextData.err) {
      await Sentry.captureUnderscoreErrorException(contextData);
    }

    return { statusCode };
  },
]);

export default Error;

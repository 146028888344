/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@scorenco/core';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type WebEventCheckOldUrlQueryVariables = Types.Exact<{
  pathname: Types.Scalars['String']['input'];
}>;

export type WebEventCheckOldUrlQuery = {
  __typename: 'query_root';
  competitions_event?:
    | Array<{
        __typename: 'competitions_event';
        id: number;
        detail?:
          | Array<{
              __typename: 'competitions_event_detail';
              url?: string | undefined;
            }>
          | undefined;
      }>
    | undefined;
};

export type WebClubCheckOldUrlQueryVariables = Types.Exact<{
  pathname: Types.Scalars['String']['input'];
}>;

export type WebClubCheckOldUrlQuery = {
  __typename: 'query_root';
  clubs_club?:
    | Array<{ __typename: 'clubs_club'; url?: string | undefined }>
    | undefined;
};

export type WebTeamCheckOldUrlQueryVariables = Types.Exact<{
  pathname: Types.Scalars['String']['input'];
}>;

export type WebTeamCheckOldUrlQuery = {
  __typename: 'query_root';
  teams_team?:
    | Array<{ __typename: 'teams_team'; url?: string | undefined }>
    | undefined;
};

export type WebCompetitionCheckOldUrlQueryVariables = Types.Exact<{
  pathname: Types.Scalars['String']['input'];
}>;

export type WebCompetitionCheckOldUrlQuery = {
  __typename: 'query_root';
  competitions_competition?:
    | Array<{
        __typename: 'competitions_competition';
        url?: string | undefined;
      }>
    | undefined;
};

export type WebLevelForSeasonCheckOldUrlQueryVariables = Types.Exact<{
  pathname: Types.Scalars['String']['input'];
}>;

export type WebLevelForSeasonCheckOldUrlQuery = {
  __typename: 'query_root';
  competitions_level_for_season?:
    | Array<{
        __typename: 'competitions_level_for_season';
        detail?:
          | Array<{
              __typename: 'competitions_level_for_season_detail';
              url?: string | undefined;
            }>
          | undefined;
      }>
    | undefined;
};

export type WebLeagueCheckOldUrlQueryVariables = Types.Exact<{
  pathname: Types.Scalars['String']['input'];
}>;

export type WebLeagueCheckOldUrlQuery = {
  __typename: 'query_root';
  competitions_league?:
    | Array<{
        __typename: 'competitions_league';
        detail?:
          | Array<{
              __typename: 'competitions_league_detail';
              url?: string | undefined;
            }>
          | undefined;
      }>
    | undefined;
};

export const WebEventCheckOldUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebEventCheckOldUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pathname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitions_event' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'old_url' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pathname' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'detail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebEventCheckOldUrlQuery,
  WebEventCheckOldUrlQueryVariables
>;
export const WebClubCheckOldUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebClubCheckOldUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pathname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clubs_club' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'old_url' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pathname' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebClubCheckOldUrlQuery,
  WebClubCheckOldUrlQueryVariables
>;
export const WebTeamCheckOldUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebTeamCheckOldUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pathname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_team' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'old_url' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pathname' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebTeamCheckOldUrlQuery,
  WebTeamCheckOldUrlQueryVariables
>;
export const WebCompetitionCheckOldUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebCompetitionCheckOldUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pathname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitions_competition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'old_url' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pathname' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebCompetitionCheckOldUrlQuery,
  WebCompetitionCheckOldUrlQueryVariables
>;
export const WebLevelForSeasonCheckOldUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebLevelForSeasonCheckOldUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pathname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitions_level_for_season' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'old_url' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pathname' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'detail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebLevelForSeasonCheckOldUrlQuery,
  WebLevelForSeasonCheckOldUrlQueryVariables
>;
export const WebLeagueCheckOldUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebLeagueCheckOldUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pathname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitions_league' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'old_url' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pathname' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'detail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebLeagueCheckOldUrlQuery,
  WebLeagueCheckOldUrlQueryVariables
>;
